<template>

  <div>
    <section id="login_wrapper">
      <div class="login_position">
        <div class="login_box card border-0">
          <div class="card-body border-0">
            <form v-on:submit.prevent="submitForm" @keydown="clearError" class="row">

              <div class="col-12">
                <h1>Password Recovery</h1>
              </div>

              <div class="col-12 custom-form-group">
                <div class="alert alert-success" v-if="success" role="alert">
                  Congratulations! the reset password link was send to email.
                </div>
                <label class="custom-input-label" for="email">Email</label>
                <input id="email" type="email" name="email" v-model="form.email" class="lss-form-control" placeholder="example@example.com">
              </div>
              <div class="col-12 d-block d-sm-flex justify-content-between align-items-center mt-40">
                <div class="d-block d-md-flex text-center mt-4 mt-sm-0">
                  <div class="mr-0 mr-sm-3">Already have an account?</div>
                  <a href="/" class="ms-1">Sign In</a>
                </div>
                <button class="btn-sent-yellow-lg">Recover</button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
export default {
  name: "RecoverPassword",
  data(){
    return{
      success:false,
      isLoading: false,
      fullPage: true,
      isActive: true,
      form:{
        email:''
      }
    }
  },
}
</script>

<style scoped>

</style>